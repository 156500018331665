(function () {
  "use strict";

  angular.module("lariAidApp").controller("InvestigationController", InvestigationController);

  InvestigationController.$inject = [
    "$rootScope",
    "$scope",
    "Auth",
    "Principal",
    "LoginService",
    "$state",
    "$mdSidenav",
    "$element",
    "JhiLanguageService",
    "$translate",
    "BaseFactory",
    "APP",
    "communicationService",
    "$timeout",
    "InvestigationService",
    "$mdMedia",
    "$sessionStorage",
    "$mdSelect",
    "StorageDelete",
    "CommonService",
    "LibraryRequestService",
    "LibraryService",
    "$location",
    "$mdDialog",
    "notifications",
    "BASE_URL",
    "$cookies",
    "InvestigationFormService",
    "$http"
  ];

  function InvestigationController(
    $rootScope,
    $scope,
    Auth,
    Principal,
    LoginService,
    $state,
    $mdSidenav,
    $element,
    JhiLanguageService,
    $translate,
    BaseFactory,
    APP,
    communicationService,
    $timeout,
    InvestigationService,
    $mdMedia,
    $sessionStorage,
    $mdSelect,
    StorageDelete,
    CommonService,
    LibraryRequestService,
    LibraryService,
    $location,
    $mdDialog,
    notifications,
    BASE_URL,
    $cookies,
    InvestigationFormService,
    $http
  ) {
    var vm = this;
    var searchText;
    $scope.media = $mdMedia;

    vm.allActiveType = {};
    vm.allActiveType.checked = false;
    vm.selectedItemList = [];
    vm.selectedIndex = [];
    vm.selectCloseList = [];
    vm.filterObj = {};
    vm.loadingListData = false;
    vm.selectedCountries = "";
    vm.filterObj.statusKey = [];
    vm.filterObj.dnaTests = [];
    vm.entityReviewItems = [];
    vm.closedRequests = ["Approved", "Altered"];
    vm.entityRequestOld = {};
    vm.entityRequestNew = {};
    vm.locationRequestNew = {};
    vm.locationRequestNew.countryObj = {};
    vm.locationRequestNew.provinceObj = {};
    vm.locationRequestNew.cityObj = {};
    vm.locationRequestNew.sectorObj = {};

    vm.entityRequestOld.countryObj = {};
    vm.entityRequestOld.provinceObj = {};
    vm.entityRequestOld.cityObj = {};
    vm.entityRequestOld.sectorObj = {};
    $scope.noOfDays = null;
    $scope.firstName = "";
    $scope.lastName = "";
    $scope.requestDuration = null;
    $scope.requestedByFirstName = "";
    $scope.requestedByLastName = "";
    vm.inputIsHideObject = {};
    vm.relpaceinputfieldObject = {};
    vm.relpaceinputfieldObject.country = false;
    vm.relpaceinputfieldObject.province = false;
    vm.relpaceinputfieldObject.city = false;
    vm.relpaceinputfieldObject.sector = false;
    vm.locationRequestNew = {};
    vm.locationRequestNew.countryObj = {};
    vm.locationRequestNew.provinceObj = {};
    vm.locationRequestNew.cityObj = {};
    vm.locationRequestNew.sectorObj = {};
    vm.mergeFlag = false;
    vm.mergeEntity = false;
    vm.locationIdValidation = false;

    var listTotalLength = 0;
    vm.dnaTestShowViewOpenInv = true;
    vm.dnaTestShowViewClosedInv = true;
    vm.dnaTestReceviedShowViewOpenInv = true;
    vm.dnaTestReceviedShowViewClosedInv = true;
    vm.dnaCenterShowViewOpenInv = true;
    vm.dnaCenterShowViewClosedInv = true;
    $scope.dateOfDnaTestOpenInv = false;
    $scope.dateOfDnaTestRecivedOpenInv = false;
    $scope.dnaCenterOpenInv = false;
    $scope.dateOfDnaTestClosedInv = false;
    $scope.dateOfDnaTestRecivedClosedInv = false;
    $scope.dnaCenterClosedInv = false;
    $scope.dnaIcon = true;
    /*vm.user =  $cookies.getObject('sessionDetails');*/
    vm.user = CommonService.getCookieUser().cookieUser;

    vm.endlessScrollRetain = {};

    if ($sessionStorage.endlessScrollRetain) {
      vm.endlessScrollRetain = $sessionStorage.endlessScrollRetain;
    }

    //Constants
    vm.APP = APP;
    vm.roleAnalyst = vm.APP.GLOBAL.ROLE_ANALYST;
    vm.endless = vm.APP.GLOBAL.ENDLESS;
    vm.roleInvestigator = vm.APP.GLOBAL.ROLE_INVESTIGATOR;

    vm.filterObj.page = 0;
    vm.filterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
    vm.investigationList = [];
    vm.loadingData = false;

    vm.filters = {};
    vm.filters.regions = [];
    vm.filters.isByClosedDate = undefined;
    vm.filters.isDnaByClosedDate = undefined;
    vm.filters.isRetainedCloseFilter = false;
    vm.filters.isDnaNotRequiredFilter;
    vm.allCountries = false;
    var investigation = {};
    vm.sortBy = {};

    $scope.myDate = new Date();

    $scope.maxDates = new Date($scope.myDate.getFullYear(), $scope.myDate.getMonth(), $scope.myDate.getDate());

    $scope.maxDate = new Date($scope.myDate.getFullYear(), $scope.myDate.getMonth(), $scope.myDate.getDate());

    $scope.change = function (temp) {
      $scope.minDates = temp;
    };

    $scope.changeTo = function (temp) {
      $scope.maxDates = temp;
    };

    vm.isMobile = false;
    if (!$scope.media("gt-sm")) {
      vm.isMobile = true;
    } else {
      vm.isMobile = false;
    }

    vm.clearName = function (name, searchKey, dropValue) {
      if (!vm.filters[name] || !vm.filters[name].id) {
        $scope[searchKey] = undefined;
        vm[dropValue] = undefined;
      }
    };

    vm.clearDate = function (startDate, endDate) {
      if (vm.filters[startDate] == undefined || vm.filters[endDate] == undefined) {
        vm.filters[startDate] = undefined;
        vm.filters[endDate] = undefined;
      }
    };

    vm.deleteEndlessDetails = function () {
      vm.endlessScrollRetain = {};
      delete $sessionStorage.endlessScrollRetain;
    };

    vm.searchInvestigatorAnalyst = function (searchKey, roleId) {
      if (searchKey) {
        if (roleId == vm.roleInvestigator) {
          vm.investigatorLoader = true;
          vm.analystLoader = false;
        } else if (roleId == vm.roleAnalyst) {
          vm.investigatorLoader = false;
          vm.analystLoader = true;
        }
        var data = CommonService.getUsersList(roleId, searchKey);
        data.then(function (response) {
          if (roleId == vm.roleInvestigator) {
            vm.investigatorVO = response;
            vm.investigatorLoader = false;
          } else if (roleId == vm.roleAnalyst) {
            vm.analystVO = response;
            vm.analystLoader = false;
          }
        });
      }
    };

    /* Method name           : vm.searchDnaCenter()
     * Method Description    : This method is used to get all DNA center by searchKey
     * @author Dinesh Sekar
     */
    vm.searchDnaCenter = function (searchKey, Investigation) {
      if (searchKey != null && searchKey.length >= 2) {
        vm.dnaFilter = {};
        vm.dnaFilter.searchKey = searchKey;
        vm.dnaFilter.entityTypeKey = "DNA_CENTER";
        var data = InvestigationFormService.addorSuggestEntityRequest(vm.dnaFilter);
        data.then(function (response) {
          if (Investigation == "openInvestigation") {
            vm.dnaCenterValueOpenInv = response;
            vm.dnaCenterValueLoaderOpenInv = false;
          } else if (Investigation == "ClosedInvestigation") {
            vm.dnaCenterValueClosedInv = response;
            vm.dnaCenterValueClosedInvLoader = false;
          }
        });
      }
    };

    vm.searchProspects = function (prospectSearchKey) {
      vm.filterObj.searchKey = prospectSearchKey;
      vm.filters.searchKey = prospectSearchKey;
      if (prospectSearchKey.length > 1 || prospectSearchKey.length == 0) {
        //vm.getInvestigationList('');//Get filter count and investigation list
        vm.getFilterList(undefined, true);
      }
      /* else {

            //vm.getInvestigationList('');//Get filter count and investigation list
             vm.getFilterList(undefined,true);
           }

             vm.filters.searchKey = prospectSearchKey;
           }*/
    };

    vm.clearSearchProspects = function () {
      vm.prospectSearch = "";
      vm.searchProspects("");
    };

    //set the selected status key list in filterObj
    vm.setStatusKeyFilter = function () {
      if (vm.selectedItemList.length > 0) {
        //for DNA CR split the statusKey to openStatusKey and closedStatusKey
        //To get investigation statusCount,headerCount and investigationlisting easier
        vm.filterObj.statusKey = [];
        vm.filterObj.openInvStatusKey = [];
        vm.filterObj.closedInvStatusKey = [];
        vm.prevFilterList = [];
        angular.forEach(vm.selectedItemList, function (data, key) {
          if (data.count > 0 || data.id != null) {
            if (data.id <= 13) {
              vm.filterObj.openInvStatusKey.push(data.id);
              vm.filterObj.statusKey.push(data.id);
            }
            if (data.id > 13) {
              vm.filterObj.closedInvStatusKey.push(data.id);
              vm.filterObj.statusKey.push(data.id);
            }
          }
          vm.prevFilterList.push(data);
        });
      }
      if (vm.prevClosedList.length > 0) {
        if (angular.isUndefined(vm.selectedItemList) || vm.selectedItemList.length == 0) {
          vm.filterObj.statusKey = [];
          vm.filterObj.openInvStatusKey = [];
          vm.filterObj.closedInvStatusKey = [];
        }
        angular.forEach(vm.prevClosedList, function (data, key) {
          if (data.count > 0 || data.id != null) {
            if (data.id <= 13) {
              vm.filterObj.openInvStatusKey.push(data.id);
              vm.filterObj.statusKey.push(data.id);
            }
            if (data.id > 13) {
              vm.filterObj.closedInvStatusKey.push(data.id);
              vm.filterObj.statusKey.push(data.id);
            }
          }
        });
      } else if (vm.prevClosedList.length == 0) {
        if (angular.isUndefined(vm.selectedItemList) || vm.selectedItemList.length == 0) {
          vm.filterObj.statusKey = [];
          vm.filterObj.openInvStatusKey = [];
          vm.filterObj.closedInvStatusKey = [];
        } else {
          vm.filterObj.statusKey = [];
          vm.filterObj.openInvStatusKey = [];
          vm.filterObj.closedInvStatusKey = [];
          angular.forEach(vm.selectedItemList, function (data, key) {
            if (data.count > 0 || data.id != null) {
              if (data.id <= 13) {
                vm.filterObj.openInvStatusKey.push(data.id);
                vm.filterObj.statusKey.push(data.id);
              }
              if (data.id > 13) {
                vm.filterObj.closedInvStatusKey.push(data.id);
                vm.filterObj.statusKey.push(data.id);
              }
            }
          });
        }
      }
    };

    var clickingTimer;
    vm.getInvestigationList = function (type, headerStatusType) {
      clearTimeout(clickingTimer);

      clickingTimer = setTimeout(function () {
        vm.setStatusKeyFilter();
        if (angular.isUndefined(vm.sortBy) || angular.isUndefined(vm.sortBy.val)) {
          vm.filterObj.sortBy = "daysCountAsc";
          vm.filterObj.sortOrder = "asc";
        }
        vm.loadingData = true;
        if (type != vm.endless && vm.endlessScrollRetain && !vm.endlessScrollRetain.index) {
          vm.investigationList = [];
        }

        if (headerStatusType) {
          if (headerStatusType == vm.APP.INVESTIGATION.HEADER_TYPE_ALL) {
            vm.filterObj.daysCount = vm.APP.INVESTIGATION.HEADER_TYPE_ALL_VALUE;
          } else if (headerStatusType == vm.APP.INVESTIGATION.HEADER_TYPE_THIRTY) {
            vm.filterObj.daysCount = vm.APP.INVESTIGATION.ONE_MONTH;
          } else if (headerStatusType == vm.APP.INVESTIGATION.HEADER_TYPE_FIFTEEN) {
            vm.filterObj.daysCount = vm.APP.INVESTIGATION.HALF_MONTH;
          }
        }

        if (vm.investigationList && vm.investigationList.length == 0) {
          vm.filterObj.page = 0;
          listTotalLength = 0;
        }

        //Set size of the list to the previous size
        if (vm.endlessScrollRetain && vm.endlessScrollRetain.index) {
          vm.filterObj.page = 0;
          if (vm.endlessScrollRetain.page > vm.APP.GLOBAL.DEFAULT_SIZE) {
            vm.filterObj.size = vm.endlessScrollRetain.page;
          } else {
            vm.filterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
          }
        } else {
          vm.filterObj.size = vm.APP.GLOBAL.DEFAULT_SIZE;
        }
        /*else if(vm.investigationList.length > 0){
                vm.filterObj.page = vm.investigationList.length;
              }*/

        if (vm.prospectSearch != undefined && (vm.prospectSearch.length > 1 || vm.prospectSearch.length == 0)) {
          vm.filterObj.searchKey = vm.prospectSearch;
          vm.filters.searchKey = vm.prospectSearch;
        }

        if (vm.filterObj.page < listTotalLength || listTotalLength == 0) {
          var data = InvestigationService.getInvestigationLists(vm.filterObj);
          data.then(
            function (response) {
              vm.noMoreMembers = false;
              if (response && !angular.equals({}, response) && response.totalCount) {
                listTotalLength = response.totalCount;
                angular.forEach(response.investigationList, function (value, key) {
                  if (value.birthDate != "" && value.birthDate != null) {
                    value.dobMonth = value.birthDate.substring(0, 3);
                    value.dobDay = value.birthDate.substring(3, 7);
                    value.dobYear = value.birthDate.substring(7, 12);
                  }
                  if (vm.investigationList == null) {
                    vm.investigationList = [];
                    vm.investigationList.push(value);
                  } else {
                    vm.investigationList.push(value);
                  }
                });
                vm.filterObj.page = vm.investigationList.length;

                if (vm.endlessScrollRetain && vm.endlessScrollRetain.index) {
                  var locationHash = vm.endlessScrollRetain.index;
                  $location.hash(locationHash);
                  $timeout(function () {
                    $location.url($location.path());
                  }, 500);
                }

                //Endless retain
                vm.endlessScrollRetain.page = vm.filterObj.page;

                //Remove index from this object
                if (vm.endlessScrollRetain.index) {
                  vm.endlessScrollRetain.index = undefined;
                }

                if (vm.filterObj.page == listTotalLength) {
                  vm.loadingData = false;
                  vm.noMembers = false;
                  vm.noMoreMembers = true;
                }

                if (vm.filterObj.page <= vm.APP.GLOBAL.DEFAULT_SIZE) {
                  vm.noMoreMembers = false;
                }
              } else if (angular.isUndefined(response)) {
                vm.loadingData = false;
                vm.noMembers = true;
                vm.noMoreMembers = false;
              }

              vm.loadingData = false;
              vm.loadingListData = false;
            },
            function (reason) {
              vm.loadingData = false;
              vm.loadingListData = false;
              return reason;
            }
          );
        } else {
          vm.loadingData = false;
          vm.loadingListData = false;
          vm.noMoreMembers = true;
          vm.noMembers = false;
        }
        $sessionStorage.subFilters = vm.filters;
        $sessionStorage.prevFilterList = vm.prevFilterList;
      }, 200);
    };

    $scope.callListfun = function () {
      vm.getInvestigationList(vm.endless);
    };

    /**
     * To get all the countries in the first call
     */
    vm.getAllRegions = function () {
      vm.regionsLoader = true;
      vm.translations = [];
      // Get the Spanish name details from translation JSON file
      $http
        .get("i18n/es/dashboard.json?version=" + localStorage.getItem("lari_currentVersion"))
        .then(function (response) {
          vm.translations = response.data;
        })
        .catch(function (error) {
          console.error("Error loading translations:", error);
        });
      var data = CommonService.getBirthCountry();
      data.then(function (response) {
        vm.allRegions = response;
        angular.forEach(vm.allRegions, function (item) {
          // Set the Spanish Names for countries
          if (vm.translations && vm.translations.dashboard) {
            vm.countryList = vm.translations.dashboard.filters.countries;
            angular.forEach(vm.countryList, function (key, value) {
              if (item.name == value) {
                item.spanishName = key;
              }
            });
          }
          item.hidden = false;
          angular.forEach(vm.filters.regions, function (region) {
            if (region.id === item.id) {
              item.hidden = true;
            }
          });
        });
        vm.regionsLoader = false;
      });
    };

    /**
     * To show/hide the selected countries
     */
    vm.showSelectedCountry = function (id, flag) {
      if (!flag) {
        vm.allCountries = false;
      }
      angular.forEach(vm.allRegions, function (item) {
        if (item.id == id) {
          item.hidden = flag;
        }
      });
      $timeout(function () {
        if (vm.allRegions.length === vm.filters.regions.length) {
          vm.allCountries = true;
        }
      }, 0);
    };

    /**
     * Will execute when All countries option is chosed
     */
    vm.selectAllCountries = function () {
      vm.regionsLoader = true;
      vm.filters.regions = [];
      if (!vm.allCountries) {
        angular.forEach(vm.allRegions, function (item) {
          item.hidden = true;
        });
        $timeout(function () {
          angular.forEach(vm.allRegions, function (item) {
            vm.filters.regions.push(item);
          });
          vm.regionsLoader = false;
        }, 0);
      } else {
        angular.forEach(vm.allRegions, function (item) {
          item.hidden = false;
        });
        vm.regionsLoader = false;
      }
    };

    /**
     * To show the total number of countries selected
     */
    vm.selectCountry = function () {
      if (vm.filters.regions.length === 247) {
        vm.selectedCountries = vm.APP.LIBRARY.COUNTRIES + " " + vm.APP.INVESTIGATION.COUNTRIES;
      } else {
        vm.selectedCountries =
          "(" + vm.filters.regions.length + ")" + " " + vm.APP.INVESTIGATION.COUNTRIES + " " + "selected";
      }
    };

    $element.find("input").on("keydown", function (ev) {
      ev.stopPropagation();
    });
    vm.toggleLeft = toggleLeft("right");

    function toggleLeft(navID) {
      return function () {
        $mdSidenav(navID).toggle();
      };
    }

    vm.collapseAll = function (data) {
      for (var i in vm.accordianData) {
        if (vm.accordianData[i] != data) {
          vm.accordianData[i].expanded = false;
        }
      }
      data.expanded = !data.expanded;
    };
    $scope.toggleLeft = buildToggler("left");
    $scope.toggleRight = buildToggler("right");

    function buildToggler(componentId) {
      return function () {
        $mdSidenav(componentId).toggle();
      };
    }

    vm.searchAgent = function (agentSearchKey) {
      if (agentSearchKey) {
        vm.agentLoader = true;
        var data = CommonService.getAgentLists(agentSearchKey);
        data.then(
          function (response) {
            vm.agentVO = response;
            vm.agentLoader = false;
          },
          function (reason) {
            return reason;
          }
        );
      }
      $scope.resizeOption();
    };

    $scope.resizeOption = function () {
      $timeout(function () {
        $(window).trigger("resize");
      }, 500);
    };

    $scope.TimeVO = {
      startDate: "28/12/2016",
      endDate: "28/12/2016"
    };

    var clickingTimerHeader;
    vm.getHeaderCount = function () {
      clearTimeout(clickingTimerHeader);
      clickingTimerHeader = setTimeout(function () {
        vm.setStatusKeyFilter();
        var data = InvestigationService.getHeaderCount(vm.filterObj);
        data.then(
          function (response) {
            vm.statusReport = response;
          },
          function (reason) {
            vm.loadingListData = false;
          }
        );
      }, 200);
    };

    vm.getSortedList = function (sortBy, isCallRequired) {
      vm.filterObj.sortBy = sortBy;
      if (sortBy == "daysCountDesc") {
        vm.filterObj.sortOrder = "desc";
      } else if (sortBy == "daysCountAsc") {
        vm.filterObj.sortOrder = "asc";
      } else if (sortBy == "firstName") {
        vm.filterObj.sortOrder = "asc";
      }

      $sessionStorage.sortBy = sortBy;

      if (!isCallRequired) {
        vm.getInvestigationList(""); //Prevent call from vm.investigationInit function.
      }
    };

    /**
     * To select the All active check box, when all the open investigations is checked.
     */
    vm.selectAllActive = function () {
      vm.test = false;
      angular.forEach(vm.openInvestigationList, function (data, key) {
        if (!data.checked) {
          vm.test = true;
        }
      });
      if (!vm.test) {
        vm.allActiveType.checked = true;
      }
    };

    /* Method name           :vm.dnaTestFilters()
     * Method Description    : This method is used to push and splice the value for new DNA filters
     * @author Dinesh Sekar
     */
    vm.dnaTestFilters = function(value,filter){
			vm.filterObj.dnaTests = [];
			if(filter == 'dnaNotRequired'){
				if(!value){
					$scope.firstFilter = false;
				}else{
					$scope.firstFilter = true;
				}
			}

			if(filter == 'dnaTestPending'){
				if(!value){
					$scope.secondFilter = false
				}else{
					$scope.secondFilter = true;
				}	
			}

			if(filter == 'dnaTestConducted'){
				if(!value){
					$scope.thirdFilter = false;
				}else{
					$scope.thirdFilter = true;
				}
			}

			if(!$scope.firstFilter && $scope.firstFilter != undefined){
				vm.filterObj.dnaTests.push("No");
			}
			if(!$scope.secondFilter && $scope.secondFilter != undefined){
				vm.filterObj.dnaTests.push("Yes");
			}
			if(!$scope.thirdFilter && $scope.thirdFilter != undefined){
				vm.filterObj.dnaTests.push("Selected");
			}
			vm.getFilterList();
		};


    vm.getFilterList = function (daysCount, loader, headerStatusType) {
      //To disable checkboxes

      if (vm.isMobile || !angular.isUndefined(headerStatusType)) {
        vm.loadingListData = true;
      }
      /*vm.userRole = vm.user.authorities[0];*/
      vm.userRole = CommonService.getCookieUser().loggedUser;
      vm.previousOpenList = angular.copy(vm.openInvestigationList);
      //vm.closeInvestigationList = [];
      //vm.openInvestigationList = [];
      //vm.statusList = []; //Commented the lines, so that while clicking on the filter will not hide the list in html page during runtime.
      vm.daysList = [];

      if ($sessionStorage.subFilters) {
        vm.filters = $sessionStorage.subFilters;
      }

      if (vm.filters.agents && vm.filters.agents.id) {
        vm.filterObj.agent = vm.filters.agents.id;
      }
      if (vm.filters.investigators && vm.filters.investigators.id) {
        vm.filterObj.investigator = vm.filters.investigators.id;
      }
      if (vm.filters.analysts && vm.filters.analysts.id) {
        vm.filterObj.analyst = vm.filters.analysts.id;
      }
      if (vm.filters.club && vm.filters.club.id) {
        vm.filterObj.club = vm.filters.club.id;
      }
      if (vm.filters.regions && vm.filters.regions.length > 0) {
        vm.filterObj.region = []; //vm.selectedCountriesNames = [];
        angular.forEach(vm.filters.regions, function (item, index) {
          vm.filterObj.region.push(item.id);
          //vm.selectedCountriesNames.push(item.name);
        });
        //vm.selectedCountries = vm.selectedCountriesNames.join(', ');
      }
      if (vm.filters.registrationTypes && vm.filters.registrationTypes.id) {
        vm.filterObj.registrationType = vm.filters.registrationTypes.id;
      }
      if (vm.filters.investigationTypes && vm.filters.investigationTypes.id) {
        vm.filterObj.investigationType = vm.filters.investigationTypes.id;
      }
      if (vm.filters.daysCount && vm.filters.daysCount.id) {
        vm.filterObj.daysCount = vm.filters.daysCount.id;
      }
      if (vm.filters.searchKey) {
        vm.filterObj.searchKey = vm.filters.searchKey;
        vm.prospectSearch = vm.filters.searchKey;
      } else {
        vm.filterObj.searchKey = "";
      }
      if (daysCount) {
        vm.filterObj.investigationDaysCount = daysCount;
      }
      if (vm.filters.isByClosedDate) {
        vm.filterObj.isByClosedDate = vm.filters.isByClosedDate;
        if ($sessionStorage.isRetainedCloseFilter) {
          vm.filters.isRetainedCloseFilter = true;
        }
        $sessionStorage.isRetainedCloseFilter = true;
      } else {
        $sessionStorage.isRetainedCloseFilter = false;
        vm.filters.isRetainedCloseFilter = false;
      }

      if (vm.filters.startDate) {
        vm.filters.startDate = moment(vm.filters.startDate).format("YYYY-MM-DD");
        vm.filterObj.startDate = vm.filters.startDate;
        vm.filters.startDate = new Date(vm.filters.startDate + "T00:00:00");
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.startDate = undefined;
      }
      if (vm.filters.endDate) {
        vm.filters.endDate = moment(vm.filters.endDate).format("YYYY-MM-DD");
        vm.filterObj.endDate = vm.filters.endDate;
        vm.filters.endDate = new Date(vm.filters.endDate + "T00:00:00");
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.endDate = undefined;
      }

      //For DNA CR introduce a new dnaCenter filter
      if (vm.filters.dnaCenterOpenInv && vm.filters.dnaCenterOpenInv.id) {
        vm.filterObj.dnaCenter = vm.filters.dnaCenterOpenInv.id;
      }

      //For DNA CR introduce a Two new filters
      if (vm.filters.startDateOfDnaTestOpenInv) {
        vm.filters.startDateOfDnaTestOpenInv = moment(vm.filters.startDateOfDnaTestOpenInv).format("YYYY-MM-DD");
        vm.filterObj.startDateOfDnaTest = vm.filters.startDateOfDnaTestOpenInv;
        vm.filters.startDateOfDnaTestOpenInv = new Date(vm.filters.startDateOfDnaTestOpenInv + "T00:00:00");
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.startDateOfDnaTest = undefined;
      }
      if (vm.filters.endDateOfDnaTestOpenInv) {
        vm.filters.endDateOfDnaTestOpenInv = moment(vm.filters.endDateOfDnaTestOpenInv).format("YYYY-MM-DD");
        vm.filterObj.endDateOfDnaTest = vm.filters.endDateOfDnaTestOpenInv;
        vm.filters.endDateOfDnaTestOpenInv = new Date(vm.filters.endDateOfDnaTestOpenInv + "T00:00:00");
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.endDateOfDnaTest = undefined;
      }

      if (vm.filters.startDateOfDnaTestRecivedOpenInv) {
        vm.filters.startDateOfDnaTestRecivedOpenInv = moment(vm.filters.startDateOfDnaTestRecivedOpenInv).format(
          "YYYY-MM-DD"
        );
        vm.filterObj.startDateOfDnaTestRecived = vm.filters.startDateOfDnaTestRecivedOpenInv;
        vm.filters.startDateOfDnaTestRecivedOpenInv = new Date(
          vm.filters.startDateOfDnaTestRecivedOpenInv + "T00:00:00"
        );
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.startDateOfDnaTestRecived = undefined;
      }
      if (vm.filters.endDateOfDnaTestRecivedOpenInv) {
        vm.filters.endDateOfDnaTestRecivedOpenInv = moment(vm.filters.endDateOfDnaTestRecivedOpenInv).format(
          "YYYY-MM-DD"
        );
        vm.filterObj.endDateOfDnaTestRecived = vm.filters.endDateOfDnaTestRecivedOpenInv;
        vm.filters.endDateOfDnaTestRecivedOpenInv = new Date(vm.filters.endDateOfDnaTestRecivedOpenInv + "T00:00:00");
        vm.filterObj.investigationDaysCount = undefined;
      } else {
        vm.filterObj.endDateOfDnaTestRecived = undefined;
      }
      $sessionStorage.subFilters = vm.filters;

      vm.setStatusKeyFilter();

      if (loader != undefined) vm.filterCountLoader = false;
      else vm.filterCountLoader = true;
      var data = InvestigationService.loadFilterList(vm.filterObj);
      data.then(
        function (response) {
          vm.filterCountLoader = false;
          if (vm.userRole != vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            vm.openInvestigationList = response.Open_Investigations;
            vm.closeInvestigationList = response.Closed_Investigations;
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            vm.statusList = response.Investigator_Status_List;
            vm.daysList = response.Investigator_Days_List;
          }

          if (vm.userRole != vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            vm.loadFilteredList(headerStatusType);
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            /*else if(vm.userRole == vm.APP.GLOBAL.ROLE_ADMIN) {
                vm.loadFilteredList();
            }*/
            if (vm.statusList.length > 0) {
              if ($sessionStorage.prevFilterList) {
                vm.prevFilterList = $sessionStorage.prevFilterList;
              }

              if (vm.prevFilterList.length > 0) {
                angular.forEach(vm.statusList, function (data, key) {
                  angular.forEach(vm.prevFilterList, function (val, index) {
                    if (data.id == val.id) {
                      data.checked = true;
                    }
                  });
                });
              } else {
                if ($sessionStorage.selectedItemList) {
                  vm.selectedItemList = $sessionStorage.selectedItemList;
                }
                angular.forEach(vm.statusList, function (data, key) {
                  angular.forEach(vm.selectedItemList, function (val, index) {
                    if (data.id == val.id) {
                      data.checked = true;
                    }
                  });
                });
              }
              vm.selectedItemList = [];
              angular.forEach(vm.statusList, function (data, key) {
                if (data.checked) {
                  vm.selectedItemList.push(data);
                }
              });
            }
            if (vm.daysList.length > 0) {
              if ($sessionStorage.prevClosedList) {
                vm.prevClosedList = $sessionStorage.prevClosedList;
              }
              if (vm.prevClosedList.length > 0) {
                angular.forEach(vm.daysList, function (data, key) {
                  angular.forEach(vm.prevClosedList, function (val, index) {
                    if (data.id == val.id) {
                      data.checked = true;
                    }
                  });
                });
              } else {
                angular.forEach(vm.daysList, function (data, key) {
                  data.checked = false;
                });
              }
              vm.selectCloseList = [];
              angular.forEach(vm.daysList, function (data, key) {
                if (data.checked) {
                  vm.selectCloseList.push(data);
                }
              });
            }
            vm.getHeaderCount(); // call header count
            //call investigation list
            if (angular.isUndefined(headerStatusType)) vm.getInvestigationList("");
            else vm.getInvestigationList("", headerStatusType);
          }
          /*else if(vm.userRole == vm.APP.GLOBAL.ROLE_ANALYST) {
              vm.loadFilteredList();
            }*/
        },
        function (reason) {
          vm.loadingListData = false;
        }
      );
    };

    /* Method name           : vm.checkboxHideAndShow
     * Method Description    : This method is used to checkbox vaildation for DNA filters
     * @author Dinesh Sekar
     */
    vm.checkboxHideAndShow = function (value, filter) {
      if (filter == "dateOfDnaTest") {
        if (value == false) {
          vm.dnaTestShowViewOpenInv = false;
        } else {
          vm.dnaTestShowViewOpenInv = true;
        }
      } else if (filter == "dateofDnaTestRecived") {
        if (value == false) {
          vm.dnaTestReceviedShowViewOpenInv = false;
        } else {
          vm.dnaTestReceviedShowViewOpenInv = true;
        }
      } else if (filter == "dnaCenter") {
        if (value == false) {
          vm.dnaCenterShowViewOpenInv = false;
        } else {
          vm.dnaCenterShowViewOpenInv = true;
        }
      } else if (filter == "dateOfDnaTestClosedInvestigation") {
        if (value == false) {
          vm.dnaTestShowViewClosedInv = false;
        } else {
          vm.dnaTestShowViewClosedInv = true;
        }
      } else if (filter == "dateofDnaTestRecivedClosedInvestigation") {
        if (value == false) {
          vm.dnaTestReceviedShowViewClosedInv = false;
        } else {
          vm.dnaTestReceviedShowViewClosedInv = true;
        }
      } else if (filter == "dnaCenterClosedInvestigation") {
        if (value == false) {
          vm.dnaCenterShowViewClosedInv = false;
        } else {
          vm.dnaCenterShowViewClosedInv = true;
        }
      }
    };

    vm.loadFilteredList = function (headerStatusType) {
      if (vm.openInvestigationList.length > 0) {
        angular.forEach(vm.openInvestigationList, function (data, key) {
          if (key == 0) {
            vm.allActiveType = data;
            //vm.allActiveType.checked= false;
            vm.openInvestigationList.splice(0, 1);
          }
        });

        // Get selected list from storage
        if ($sessionStorage.prevFilterList) {
          vm.prevFilterList = $sessionStorage.prevFilterList;
        }

        if (vm.prevFilterList.length > 0) {
          angular.forEach(vm.openInvestigationList, function (data, key) {
            angular.forEach(vm.prevFilterList, function (val, index) {
              if (data.id == val.id) {
                data.checked = true;
              }
            });
          });
        } else {
          if ($sessionStorage.selectedItemList) {
            vm.selectedItemList = $sessionStorage.selectedItemList;
          }
          angular.forEach(vm.openInvestigationList, function (data, key) {
            angular.forEach(vm.selectedItemList, function (val, index) {
              if (data.id == val.id) {
                data.checked = true;
              }
            });
          });
        }
        vm.selectedItemList = [];
        angular.forEach(vm.openInvestigationList, function (data, key) {
          if (data.checked) {
            vm.selectedItemList.push(data);
          }
        });
      }
      if (vm.closeInvestigationList.length > 0) {
        if ($sessionStorage.prevClosedList) {
          vm.prevClosedList = $sessionStorage.prevClosedList;
        }

        if (vm.filters.isByClosedDate && !vm.filters.isRetainedCloseFilter) {
          vm.selectCloseList = [];
          vm.prevClosedList = [];
          angular.forEach(vm.closeInvestigationList, function (data, key) {
            if (data.count > 0) {
              data.checked = true;
              vm.selectCloseList.push(data);
              vm.prevClosedList.push(data);
            }
          });
          $sessionStorage.prevClosedList = vm.prevClosedList;
        } else if (vm.filters.isByClosedDate) {
          if (vm.prevClosedList.length > 0) {
            angular.forEach(vm.closeInvestigationList, function (data, key) {
              angular.forEach(vm.prevClosedList, function (val, index) {
                if (data.id == val.id) {
                  data.checked = true;
                }
              });
            });
          }
        } else if (vm.prevClosedList.length > 0) {
          angular.forEach(vm.closeInvestigationList, function (data, key) {
            angular.forEach(vm.prevClosedList, function (val, index) {
              if (data.id == val.id) {
                data.checked = true;
              }
            });
          });
        } else {
          angular.forEach(vm.closeInvestigationList, function (data, key) {
            data.checked = false;
          });
        }
        vm.selectCloseList = [];
        angular.forEach(vm.closeInvestigationList, function (data, key) {
          if (data.checked) {
            vm.selectCloseList.push(data);
          }
        });
      }

      vm.selectAllActive(); // To check/uncheck All active checkbox
      vm.getHeaderCount(); // call header count
      //call investigation list
      if (angular.isUndefined(headerStatusType)) vm.getInvestigationList("");
      else vm.getInvestigationList("", headerStatusType);
    };

    vm.getFilterDefaultList = function () {
      /*vm.userRole = vm.user.authorities[0];*/
      vm.userRole = CommonService.getCookieUser().loggedUser;
      vm.closeInvestigationList = [];
      vm.openInvestigationList = [];
      vm.statusList = [];
      vm.daysList = [];
      vm.filterCountLoader = true;
      var data = InvestigationService.loadFilterList(vm.filterObj);
      data.then(
        function (response) {
          vm.filterCountLoader = false;
          if (vm.userRole != vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            vm.openInvestigationList = response.Open_Investigations;
            vm.closeInvestigationList = response.Closed_Investigations;
            if (vm.filters.isByClosedDate && vm.closeInvestigationList.length > 0) {
              vm.selectCloseList = [];
              angular.forEach(vm.closeInvestigationList, function (data, key) {
                if (data.count > 0) {
                  data.checked = true;
                  vm.selectCloseList.push(data);
                } else {
                  data.checked = false;
                }
              });
            }
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            vm.statusList = response.Investigator_Status_List;
            vm.daysList = response.Investigator_Days_List;
          }

          if (vm.userRole == vm.APP.GLOBAL.ROLE_ADMIN || vm.userRole == vm.APP.GLOBAL.ROLE_COUNTRY_ADMIN) {
            if (vm.openInvestigationList.length > 0) {
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 0) {
                  vm.allActiveType = data;
                  //vm.allActiveType.checked= true;
                  vm.openInvestigationList.splice(0, 1);
                }
              });
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 0 || key == 2) {
                  data.checked = true;
                } else {
                  data.checked = false;
                }
              });
              if (vm.filterObj.isMyInvestigation == 1) {
                angular.forEach(vm.openInvestigationList, function (data, key) {
                  data.checked = true;
                });
              }
              /*if(vm.filterObj.isMyInvestigation == 0){
                    angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(key == 0 || key == 2) {
                          data.checked = true;
                        } else {
                          data.checked = false;
                        }
                      });
                  } else {
                    angular.forEach(vm.openInvestigationList, function(data, key) {
                        data.checked = true;
                    });
                  }*/
              /*vm.selectedItemList = [];
                      angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(data.checked) {
                          vm.selectedItemList.push(data);
                        }
                      });*/
            }
            /*if(vm.closeInvestigationList.length > 0) {
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                        data.checked = false;
                      });
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                      if(data.checked) {
                        vm.selectCloseList.push(data);
                      }
                      });
                  }*/
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_MANAGER) {
            if (vm.openInvestigationList.length > 0) {
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 0) {
                  vm.allActiveType = data;
                  //vm.allActiveType.checked= false;
                  vm.openInvestigationList.splice(0, 1);
                }
              });
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 4) {
                  data.checked = true;
                } else {
                  data.checked = false;
                }
              });
              if (vm.filterObj.isMyInvestigation == 1) {
                angular.forEach(vm.openInvestigationList, function (data, key) {
                  data.checked = true;
                });
              }
              /*  if(vm.filterObj.isMyInvestigation == 0){
                        angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(key == 4) {
                          data.checked = true;
                        } else {
                          data.checked = false;
                        }
                      });
                  } else {
                    angular.forEach(vm.openInvestigationList, function(data, key) {
                        data.checked = true;
                    });
                  }*/

              /*vm.selectedItemList = [];
                      angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(data.checked) {
                          vm.selectedItemList.push(data);
                        }
                      });*/
            }
            /*if(vm.closeInvestigationList.length > 0) {
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                        data.checked = false;
                      });
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                      if(data.checked) {
                        vm.selectCloseList.push(data);
                      }
                      });
                  }*/
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_READ_ONLY) {
            if (vm.openInvestigationList.length > 0) {
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 0) {
                  vm.allActiveType = data;
                  //vm.allActiveType.checked= false;
                  vm.openInvestigationList.splice(0, 1);
                }
              });
              angular.forEach(vm.openInvestigationList, function (data, key) {
                data.checked = true;
              });
              angular.forEach(vm.closeInvestigationList, function (data) {
                if (data.key == "CONFIRMED") {
                  data.checked = true;
                }
              });
            }
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
            if (vm.statusList.length > 0) {
              angular.forEach(vm.statusList, function (data, key) {
                if (key == 0) {
                  data.checked = true;
                } else {
                  data.checked = false;
                }
              });
              /*vm.selectedItemList = [];
                      angular.forEach(vm.statusList, function(data, key) {
                        if(data.checked) {
                          vm.selectedItemList.push(data);
                        }
                      });*/
            }
            if (vm.daysList.length > 0) {
              angular.forEach(vm.daysList, function (data, key) {
                data.checked = false;
              });
              /*angular.forEach(vm.daysList, function(data, key) {
                      if(data.checked) {
                        vm.selectCloseList.push(data);
                      }
                      });*/
            }
          } else if (vm.userRole == vm.APP.GLOBAL.ROLE_ANALYST) {
            if (vm.openInvestigationList.length > 0) {
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 0) {
                  vm.allActiveType = data;
                  //vm.allActiveType.checked= false;
                  vm.openInvestigationList.splice(0, 1);
                }
              });
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (key == 1) {
                  data.checked = true;
                } else {
                  data.checked = false;
                }
              });
              if (vm.filterObj.isMyInvestigation == 1) {
                angular.forEach(vm.openInvestigationList, function (data, key) {
                  data.checked = true;
                });
              }
              /*if(vm.filterObj.isMyInvestigation == 0){
                      angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(key == 3) {
                          data.checked = true;
                        } else {
                          data.checked = false;
                        }
                      });
                  } else {
                    angular.forEach(vm.openInvestigationList, function(data, key) {
                        data.checked = true;
                    });
                  }*/
              /*vm.selectedItemList = [];
                      angular.forEach(vm.openInvestigationList, function(data, key) {
                        if(data.checked) {
                          vm.selectedItemList.push(data);
                        }
                      });*/
            }
            /*if(vm.closeInvestigationList.length > 0) {
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                        data.checked = false;
                      });
                    angular.forEach(vm.closeInvestigationList, function(data, key) {
                      if(data.checked) {
                        vm.selectCloseList.push(data);
                      }
                      });
                  }*/
          }

          if (
            vm.userRole == vm.APP.GLOBAL.ROLE_ADMIN ||
            vm.userRole == vm.APP.GLOBAL.ROLE_MANAGER ||
            vm.userRole == vm.APP.GLOBAL.ROLE_ANALYST ||
            vm.userRole == vm.APP.GLOBAL.ROLE_READ_ONLY ||
            vm.userRole == vm.APP.GLOBAL.ROLE_COUNTRY_ADMIN
          ) {
            if (vm.closeInvestigationList.length > 0) {
              angular.forEach(vm.closeInvestigationList, function (data, key) {
                data.checked = false;
                vm.prevClosedList = [];
                vm.selectCloseList = [];
              });
              /*angular.forEach(vm.closeInvestigationList, function(data, key) {
                      if(data.checked) {
                        vm.selectCloseList.push(data);
                      }
                      });*/
            }
          }

          if (!$sessionStorage.selectedItemList) {
            vm.selectedItemList = [];
            if (!(vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR)) {
              angular.forEach(vm.openInvestigationList, function (data, key) {
                if (data.checked) {
                  vm.selectedItemList.push(data);
                }
              });
            } else if (vm.userRole == vm.APP.GLOBAL.ROLE_INVESTIGATOR) {
              angular.forEach(vm.statusList, function (data, key) {
                if (data.checked) {
                  vm.selectedItemList.push(data);
                }
              });
            }
          }

          vm.getHeaderCount(); // call header count
          vm.getInvestigationList(""); //call investigation list
        },
        function (reason) {}
      );
    };

    vm.getDaysFilter = function (daysVal) {
      vm.filters.daysCount = daysVal;
      vm.getFilterList();
    };

    vm.closedList = [];
    vm.prevClosedList = [];
    vm.loadCloseinvList = function (item, list) {
      //To disable checkboxes
      if (!vm.isMobile) {
        vm.loadingListData = true;
      }
      var idx = list.indexOf(item);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
      vm.selectCloseList = list;
      vm.prevClosedList = [];
      angular.forEach(vm.selectCloseList, function (data, key) {
        vm.prevClosedList.push(data);
      });

      $sessionStorage.prevClosedList = vm.prevClosedList;

      if (!vm.isMobile) {
        //vm.getInvestigationList(''); The commented call will be included in getFilterList()
        vm.getFilterList(undefined, true);
      }
      vm.deleteEndlessDetails();
    };

    vm.dateChange = function (isCallRequired, value) {
      //For DNA CR have add a two filters
      if (
        vm.filters.startDateOfDnaTestOpenInv &&
        vm.filters.endDateOfDnaTestOpenInv &&
        value == "displayDnaDateOfTestOpenInvestigation" &&
        isCallRequired &&
        !vm.isMobile
      ) {
        vm.filters.displayDnaDateOfTestOpenInvestigation =
          moment(vm.filters.startDateOfDnaTestOpenInv).format("YYYY-MM-DD") +
          " - " +
          moment(vm.filters.endDateOfDnaTestOpenInv).format("YYYY-MM-DD");
        $mdSelect.hide();
        vm.getFilterList();
      } else if (
        vm.filters.startDateOfDnaTestRecivedOpenInv &&
        vm.filters.endDateOfDnaTestRecivedOpenInv &&
        value == "displayDnaTestRecived" &&
        isCallRequired &&
        !vm.isMobile
      ) {
        vm.filters.displayDnaTestRecived =
          moment(vm.filters.startDateOfDnaTestRecivedOpenInv).format("YYYY-MM-DD") +
          " - " +
          moment(vm.filters.endDateOfDnaTestRecivedOpenInv).format("YYYY-MM-DD");
        $mdSelect.hide();
        vm.getFilterList();
      } else if (vm.filters.startDate && vm.filters.endDate && isCallRequired && !vm.isMobile) {
        vm.filters.displayDate =
          moment(vm.filters.startDate).format("YYYY-MM-DD") + " - " + moment(vm.filters.endDate).format("YYYY-MM-DD");
        $mdSelect.hide();
        vm.getFilterList();
      }
    };

    vm.investigationCreatedDateChange = function (daysCount, isCallRequired, state) {
      var from = null;
      var to = null;
      to = moment().format("YYYY-MM-DD");

      if (daysCount == vm.APP.INVESTIGATION.ONE_MONTH) {
        from = moment().subtract(30, "days").format("YYYY-MM-DD");
      } else if (daysCount == vm.APP.INVESTIGATION.THREE_MONTH) {
        from = moment().subtract(3, "months").format("YYYY-MM-DD");
      } else if (daysCount == vm.APP.INVESTIGATION.SIX_MONTH) {
        from = moment().subtract(6, "months").format("YYYY-MM-DD");
      }
      // For DNA CR have add a two filters dateOfTest and dateOfTestRecived
      if (isCallRequired && !vm.isMobile && state == "displayDnaDateOfTestOpenInvestigation") {
        //clearing from and to date filters
        vm.filters.startDateOfDnaTestOpenInv = from;
        vm.filters.endDateOfDnaTestOpenInv = to;
        $mdSelect.hide();
        vm.filterObj.startDateOfDnaTest = undefined;
        vm.filterObj.endDateOfDnaTest = undefined;
        vm.getFilterList(daysCount);
      } else if (isCallRequired && !vm.isMobile && state == "displayDnaTestRecived") {
        //clearing from and to date filters
        vm.filters.startDateOfDnaTestRecivedOpenInv = from;
        vm.filters.endDateOfDnaTestRecivedOpenInv = to;
        $mdSelect.hide();
        vm.filterObj.startDateOfDnaTestRecived = undefined;
        vm.filterObj.endDateOfDnaTestRecived = undefined;
        vm.getFilterList(daysCount);
      } else {
        vm.filters.startDate = from;
        vm.filters.endDate = to;
        $mdSelect.hide();
        vm.filterObj.startDate = undefined;
        vm.filterObj.endDate = undefined;
        vm.getFilterList(daysCount);
      }
      if (state == "displayDnaDateOfTestOpenInvestigation") {
        vm.filters.displayDnaDateOfTestOpenInvestigation =
          moment(vm.filters.startDateOfDnaTestOpenInv).format("YYYY-MM-DD") +
          " - " +
          moment(vm.filters.endDateOfDnaTestOpenInv).format("YYYY-MM-DD");
      } else if (state == "displayDnaTestRecived") {
        vm.filters.displayDnaTestRecived =
          moment(vm.filters.startDateOfDnaTestRecivedOpenInv).format("YYYY-MM-DD") +
          " - " +
          moment(vm.filters.endDateOfDnaTestRecivedOpenInv).format("YYYY-MM-DD");
      } else {
        vm.filters.displayDate =
          moment(vm.filters.startDate).format("YYYY-MM-DD") + " - " + moment(vm.filters.endDate).format("YYYY-MM-DD");
      }
    };

    vm.clear = function (type, isCallRequired) {
      /*vm.filters[name] = undefined;
           vm[search] = undefined;*/
      if (type == "investigators") {
        vm.filters.investigators = {};
        $scope.investigatorSearchKey = "";
        vm.filterObj.investigator = "";
      } else if (type == "analysts") {
        vm.filters.analysts = {};
        $scope.analystSearchKey = "";
        vm.filterObj.analyst = "";
      } else if (type == "regions") {
        vm.filters.regions = [];
        $scope.regionSearchKey = "";
        vm.filterObj.region = [];
        vm.allCountries = false;
        angular.forEach(vm.allRegions, function (item) {
          item.hidden = false;
        });
      } else if (type == "club") {
        vm.filters.club = {};
        $scope.serchFilterClub = "";
        vm.filterObj.club = "";
      } else if (type == "investigationTypes") {
        vm.filters.investigationTypes = {};
        $scope.serchFilterInvType = "";
        vm.filterObj.investigationType = "";
      } else if (type == "registrationTypes") {
        vm.filters.registrationTypes = {};
        $scope.serchFilterRegType = "";
        vm.filterObj.registrationType = "";
      } else if (type == "agents") {
        vm.filters.agents = {};
        $scope.agentSearchKey = "";
        vm.filterObj.agent = "";
      } else if (type == "time") {
        vm.filters.time = {};
        vm.filters.startDate = "";
        vm.filters.endDate = "";
        if (vm.filterObj.isByClosedDate) {
          $sessionStorage.isRetainedCloseFilter = false;
          vm.filters.isRetainedCloseFilter = false;
        }
        vm.filters.isByClosedDate = undefined;
        vm.filterObj.isByClosedDate = undefined;
        $scope.selectedDiv = 0;
        vm.filters.isClosedFilter = undefined;
        //vm.filters.displayDate = undefined;
      } else if (type == "dnaCenterOpenInvestigation") {
        $scope.dnaCenterOpenInv = false;
        vm.filters.dnaCenterOpenInv = {};
        $scope.dnaSearchKey = "";
        vm.filterObj.dnaCenterOpenInv = "";
      } else if (type == "dnaCenter") {
        $scope.dnaCenterClosedInv = false;
        vm.filters.dnaCenterClosedInv = {};
        $scope.dnaSearchKeyClosedInvestigation = "";
        vm.filterObj.dnaCenterClosedInv = "";
      } else if (type == "openInvestigationDnaDates") {
        $scope.dateOfDnaTestOpenInv = false;
        vm.filters.dnaTest = {};
        vm.filters.startDateOfDnaTestOpenInv = "";
        vm.filters.endDateOfDnaTestOpenInv = "";
        vm.filters.isDnaByClosedDate = undefined;
        vm.filterObj.isDnaByClosedDate = undefined;
        $scope.selectedDiv = 0;
        vm.filters.isDnaClosedFilter = undefined;
      } else if (type == "dnaTestRecivedOpenInvestigation") {
        $scope.dateOfDnaTestRecivedOpenInv = false;
        vm.filters.dnaTestRecived = {};
        vm.filters.startDateOfDnaTestRecivedOpenInv = "";
        vm.filters.endDateOfDnaTestRecivedOpenInv = "";
        vm.filters.isDnaTestRecivedByClosedDate = undefined;
        vm.filterObj.isDnaTestRecivedByClosedDate = undefined;
        $scope.selectedDiv = 0;
        vm.filters.isDnaRecivedClosedFilter = undefined;
      } else if (type == "dnaTestRecivedClosedInvestigation") {
        $scope.dateOfDnaTestRecivedClosedInv = false;
        vm.filters.dnaRecivedClosedInvestigation = {};
        vm.filters.startDateOfDnaTestRecivedClosedInv = "";
        vm.filters.endDateOfDnaTestRecivedClosedInv = "";
        vm.filters.isDnaTestRecivedByClosedInvestigation = undefined;
        vm.filterObj.isDnaTestRecivedByClosedInvestigation = undefined;
        $scope.selectedDiv = 0;
        vm.filters.isdnaRecivedClosedInvestigationClosedFilter = undefined;
      } else if (type == "displayDateOfDnaTestClosedInvestigation") {
        $scope.dateOfDnaTestClosedInv = false;
        vm.filters.dateOfDnaTest = {};
        vm.filters.startDateOfDnaTestClosedInv = "";
        vm.filters.endDateOfDnaTestClosedInv = "";
        vm.filters.isDateOfDnaTestByClosedDate = undefined;
        vm.filterObj.isDateOfDnaTestByClosedDate = undefined;
        $scope.selectedDiv = 0;
        vm.filters.isDateOfDnaTestClosedInvestigationFilter = undefined;
      }

      if (isCallRequired && !vm.isMobile) {
        vm.getFilterList();
      }
    };

    vm.prevFilterList = [];
    vm.toggle = function (item, list, index) {
      //To disable checkboxes
      if (!vm.isMobile) {
        vm.loadingListData = true;
      }
      var idx = list.indexOf(item);
      vm.selectedIndex.push(index);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
      vm.selectedItemList = list;
      //vm.getFilterList();
      vm.prevFilterList = [];
      angular.forEach(vm.selectedItemList, function (data, key) {
        vm.prevFilterList.push(data);
      });
      /*vm.filterObj.statusKey = [];
        angular.forEach(vm.selectedItemList, function(data, key) {
          vm.filterObj.statusKey.push(data.id);
        });
        angular.forEach(vm.closedList, function(data, key) {
          vm.filterObj.statusKey.push(data.id);
        });*/
      $sessionStorage.prevFilterList = vm.prevFilterList;
      if (!vm.isMobile) {
        /*vm.getHeaderCount(); The commented calls will be included in getFilterList()
            vm.getInvestigationList('');*/
        vm.getFilterList(undefined, true);
      }

      vm.deleteEndlessDetails();
    };

    vm.exists = function (item, list) {
      return list.indexOf(item) > -1;
    };

    vm.isChecked = function () {
      return vm.selectedItemList.length === vm.openInvestigationList.length;
    };

    vm.toggleAll = function (doSelectAll) {
      //To disable checkboxes
      if (!vm.isMobile) {
        vm.loadingListData = true;
      }
      //vm.allActiveType.checked = doSelectAll;
      if (!doSelectAll) {
        vm.selectedItemList = [];
      } else {
        vm.selectedItemList = [];
        angular.forEach(vm.openInvestigationList, function (data, key) {
          vm.selectedItemList.push(data);
        });
      }
      vm.prevFilterList = [];
      angular.forEach(vm.selectedItemList, function (data, key) {
        vm.prevFilterList.push(data);
      });
      /*vm.filterObj.statusKey = [];
          angular.forEach(vm.selectedItemList, function(data, key) {
            vm.filterObj.statusKey.push(data.id);
          });*/

      $sessionStorage.prevFilterList = vm.prevFilterList;

      if (!vm.isMobile) {
        /*vm.getHeaderCount(); The commented calls will be included in getFilterList()
            vm.getInvestigationList('');*/
        vm.getFilterList(undefined, true);
      }
      vm.deleteEndlessDetails();
    };

    vm.toggleMyInvestigation = function (myInvestigation, isCallRequired) {
      if (!myInvestigation.checked) {
        vm.filterObj.isMyInvestigation = 1;
      } else {
        vm.filterObj.isMyInvestigation = 0;
      }
      if (isCallRequired) {
        if (!vm.isMobile) {
          vm.getFilterDefaultList();
        } else {
          vm.toggleAll(!myInvestigation.checked, true);
        }
        $sessionStorage.myInvestigation = myInvestigation;
      }
      vm.deleteEndlessDetails();
    };

    vm.investigationInit = function () {
      //Init values for My Investigation
      vm.myInvestigation = { name: vm.APP.INVESTIGATION.MY_INVESTIGATIONS };
      if ($sessionStorage.myInvestigation) {
        vm.myInvestigation.checked = $sessionStorage.myInvestigation.checked;
        if (vm.myInvestigation.checked) {
          vm.toggleMyInvestigation(false, false);
        }
      } else {
        vm.myInvestigation.checked = false;
        //vm.toggleMyInvestigation(false,false);
      }

      //To get all countries
      vm.getAllRegions();

      $mdSidenav("left").close();
      //Clearing user module session storage objects
      StorageDelete.deleteUser();
      StorageDelete.deleteLibrary();

      vm.sortOrderList = [
        {
          id: "daysCountAsc",
          val: "sortbydaysAsc"
        },
        {
          id: "daysCountDesc",
          val: "sortbydaysDesc"
        },
        {
          id: "firstName",
          val: "sortbyname"
        }
      ];

      //Sort by initialization through session storage
      if ($sessionStorage.sortBy) {
        for (var i = 0; i < vm.sortOrderList.length; i++) {
          if (vm.sortOrderList[i].id == $sessionStorage.sortBy) {
            vm.sortBy = vm.sortOrderList[i];
            break;
          }
        }

        vm.getSortedList(vm.sortBy.id, true); // To get sort by list
      }

      //Filter call
      if (!$sessionStorage.subFilters && !$sessionStorage.prevFilterList && !$sessionStorage.prevClosedList) {
        vm.getFilterDefaultList();
      } else {
        vm.getFilterList();
      }

      // drop down value
      var data = BaseFactory.getClubLists();
      data.then(function (response) {
        vm.clubVO = response;
      });
      var investigationTypeResult = CommonService.getRefTermList(vm.APP.REF_TERM.INVESTIGATION_TYPE);
      investigationTypeResult.then(function (response) {
        vm.investigationVO = response;
      });

      var registrationTypeResult = CommonService.getRefTermList(vm.APP.REF_TERM.REGISTRATION_TYPE);
      registrationTypeResult.then(function (response) {
        vm.registrationVO = response;
      });
    };

    vm.investigationInit();

    vm.prospectProfile = function (id, index) {
      vm.endlessScrollRetain.index = "inv" + index;
      $sessionStorage.endlessScrollRetain = vm.endlessScrollRetain;

      vm.id = communicationService.setData("investigationID", id);
      investigation.id = id;
      $state.go("profile", investigation);
    };

    vm.sideNavClose = function () {
      $mdSidenav("right").close();
    };

    vm.exportData = function () {
      var exportFileName = vm.APP.INVESTIGATION.EXPORTFILENAME;
      var url = BASE_URL + vm.APP.INVESTIGATION.EXPORT_API + $cookies.getObject("sessionDetails").login;
      var timestamp = new Date().getTime();
      var exportUrl = url + "&timestamp=" + timestamp;

      $http({
        url: exportUrl,
        method: "POST",
        data: vm.filterObj,
        headers: {
          "Content-type": "application/json"
        },
        responseType: "arraybuffer"
      }).success(function (data) {
        var blob = new Blob([data], { type: "application/xlsx" });
        var objectUrl = URL.createObjectURL(blob);
        var anchorElement = document.createElement("a");
        document.body.appendChild(anchorElement);
        anchorElement.style.display = "none";
        anchorElement.href = objectUrl;
        anchorElement.download = exportFileName;
        anchorElement.click();
        window.URL.revokeObjectURL(objectUrl);
      });
    };

    //Method to get the reopen/reassign flag for investigation
    $scope.getReopenFlag = function (investigationId, index) {
      vm["requestFlag_" + index] = false;
      vm["requestedShow_" + index] = false;
      if (vm.APP.INVESTIGATION.ENABLEREOPENFLAG == vm.APP.GLOBAL.TRUE) {
        vm["reopenLoader_" + index] = true;
        vm["reopenInvestigator_" + index] = false;
        vm["reopenAnalyst_" + index] = false;
        vm["reopenInvestigatorInprogress_" + index] = false;
        vm["reopenAnalystInprogress_" + index] = false;

        var data = InvestigationService.getReopenFlag(investigationId);
        data.then(
          function (response) {
            var reopenFlagDetails = response;
            vm["reopenLoader_" + index] = false;
            vm["reopenInvestigator_" + index] = reopenFlagDetails.investigatorFormReOpen;
            vm["reopenAnalyst_" + index] = reopenFlagDetails.analystFormReOpen;
            vm["reopenInvestigatorInprogress_" + index] = reopenFlagDetails.investigatorFormStatus;
            vm["reopenAnalystInprogress_" + index] = reopenFlagDetails.analystFormStatus;
          },
          function (reason) {
            vm["reopenLoader_" + index] = false;
          }
        );
      }
      var data = InvestigationService.getEntityIcon(investigationId);
      data.then(function (response) {
        if (response.show == true && response.show != undefined) {
          vm["requestFlag_" + index] = true;
        }
        if (response.requestedShow == true && response.requestedShow != undefined) {
          vm["requestedShow_" + index] = true;
        }
      });
    };

    vm.showIcon = function (ev, investigationId) {
      ev.stopPropagation();
      var data = InvestigationService.getEntityReviewItemsList(investigationId);
      data.then(function (response) {
        vm.entityReviewItems = response;
      });
      vm.id = "entityIconPopupId";
      vm.showEntityRequestDialog(ev, vm.id);
    };

    vm.showEntityRequestDialog = function ($event, id) {
      $mdDialog.show({
        targetEvent: $event,
        contentElement: "#" + id,
        parent: angular.element(document.body)
      });
    };

    vm.cancel = function (formName) {
      vm.entityReviewItems = [];
      vm.msgFlagForLocation = false;
      $mdDialog.hide();
    };

    vm.checkStatus = function (status) {
      if (status) {
        return true;
      } else {
        return false;
      }
    };

    $rootScope.getEntityProfileById = function (entityId, disable, ev) {
      vm.entityProfile = {};
      vm.entityPopupLoader = true;
      vm.entityId = entityId;
      vm.mergedEntityId = undefined;
      vm.entityLocationMappingId = undefined;
      vm.isMergeRequest = false;
      vm.isRequestMerged = false;
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        var responseObj = response;
        if (disable) {
          vm.isDisable = true;
          vm.isMergeRequest = true;
          vm.mergedEntityId = response.id;
        } else {
          vm.entityRequestOld = angular.copy(responseObj);
          vm.entityNameSearchText = vm.entityRequestOld.name;
          //isForm is to know whether the request is from form section or Library Section
          vm.entityRequestOld.isForm = responseObj.isForm;
        }
        vm.entityRequestNew = angular.copy(responseObj);
        $scope.noOfDays = responseObj.noOfDays;
        $scope.firstName = responseObj.createdByFirstName;
        $scope.lastName = responseObj.createdByLastName;
        vm.entityRequestNew.entityName = LibraryService.getEntityNameObj(responseObj);
        vm.entityLocationMappingId = responseObj.locationMappingId;
        vm.getLocationMappingDataById(vm.entityLocationMappingId, disable);
        vm.getEntityTypeById(responseObj.entityTypeId, disable);
        vm.entityPopupLoader = false;
        //Get Merged Entity Profile Details
        if (responseObj.isMerged) {
          vm.isRequestMerged = true;
          vm.getMergedObj(responseObj.mergedEntityId);
        }
        if (!disable) {
          vm.getRequestPopupId(ev, vm.APP.LIBRARY.ENTITY_REQUEST);
        }
      });
    };

    vm.getLocationMappingDataById = function (locationMappingId, disable) {
      var locationData = CommonService.getLocationById(locationMappingId);
      locationData.then(function (response) {
        var responseObj = response;
        $scope.requestDuration = response.noOfDays;
        $scope.requestedByFirstName = response.requestByFirstName;
        $scope.requestedByLastName = response.requestByLastName;
        //Set location objects
        if (!disable) {
          vm.entityRequestOld.countryObj = LibraryService.getCountryObj(responseObj);
          vm.entityRequestOld.provinceObj = LibraryService.getProvinceObj(responseObj);
          vm.entityRequestOld.cityObj = LibraryService.getCityObj(responseObj);
          vm.entityRequestOld.sectorObj = LibraryService.getSectorObj(responseObj);
        }
        vm.entityRequestNew.countryObj = LibraryService.getCountryObj(responseObj);
        vm.entityRequestNew.provinceObj = LibraryService.getProvinceObj(responseObj);
        vm.entityRequestNew.cityObj = LibraryService.getCityObj(responseObj);
        vm.entityRequestNew.sectorObj = LibraryService.getSectorObj(responseObj);
      });
    };

    vm.getEntityTypeById = function (entityTypeId, disable) {
      var entityTypeData = CommonService.getRefTermList(vm.APP.REF_TERM.ENTITY_PROFILE);
      entityTypeData.then(function (response) {
        vm.entityTypeList = response;
        if (vm.entityTypeList) {
          angular.forEach(vm.entityTypeList, function (data, key) {
            if (data.id == entityTypeId) {
              if (!disable) {
                vm.entityRequestOld.entityType = data;
              }
              vm.entityRequestNew.entityType = data;
              vm.requestEntityType = data.name;
            }
          });
        }
      });
    };

    vm.getMergedObj = function (entityId) {
      vm.entityRequestNew = {};
      var data = LibraryService.getEntityProfileById(entityId);
      data.then(function (response) {
        vm.entityRequestNew = response;
        vm.entityRequestNew.entityName = LibraryService.getEntityNameObj(vm.entityRequestNew);

        if (vm.entityTypeList) {
          angular.forEach(vm.entityTypeList, function (data, key) {
            if (data.id == vm.entityRequestNew.entityTypeId) {
              vm.entityRequestNew.entityType = data;
            }
          });
        }

        var locationData = CommonService.getLocationById(vm.entityRequestNew.locationMappingId);
        locationData.then(function (response) {
          var responseObj = response;
          vm.entityRequestNew.countryObj = LibraryService.getCountryObj(responseObj);
          vm.entityRequestNew.provinceObj = LibraryService.getProvinceObj(responseObj);
          vm.entityRequestNew.cityObj = LibraryService.getCityObj(responseObj);
          vm.entityRequestNew.sectorObj = LibraryService.getSectorObj(responseObj);
        });
      });
    };

    vm.getRequestPopupId = function (ev, requestType) {
      vm.id = "";

      //Check closed status
      var idx = vm.closedRequests.indexOf("open");

      if (requestType == vm.APP.LIBRARY.LOCATION_REQUEST) {
        //View Location Request Profile for other users
        vm.id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
        if (vm.userRole == vm.APP.GLOBAL.ROLE_MANAGER || vm.isRequestMerged) {
          vm.id = vm.APP.LIBRARY.REQ_LOC_ID;
          if (idx > -1) {
            vm.id = vm.APP.LIBRARY.REQ_LOC_PROFILE_ID;
          }
        }
      } else if (requestType == vm.APP.LIBRARY.ENTITY_REQUEST) {
        vm.id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
        if (vm.userRole == vm.APP.GLOBAL.ROLE_MANAGER || vm.isRequestMerged) {
          vm.id = vm.APP.LIBRARY.REQ_ENTITY_ID;
          // For read only popup if the requestStatus as closed
          if (idx > -1) {
            vm.id = vm.APP.LIBRARY.REQ_ENTITY_PROFILE_ID;
          }
        }
      }
      vm.showRequestDialog(ev, vm.id);
    };

    vm.entityApprove = function (entityRequestNew, requestEntityForm) {
      var approveEntityRequest = {};
      vm.msgFlag = false;
      vm.isEntityApproveDisable = true;
      vm.locationIdValidation = false;
      var locationValidation = communicationService.getData("ValidateEntityLocationField");
      vm.validateLocationIds(locationValidation);
      if (entityRequestNew && requestEntityForm.$valid) {
        //Approve or Alterd
        if (!vm.isMergeRequest) {
          // For new entity name selection
          if (vm.isNewEntityName) {
            approveEntityRequest.name = vm.entityNameSearchText;
          }
          // For default entity name or change from dropdown
          else {
            approveEntityRequest.name = vm.entityRequestNew.entityName.name;
          }

          if (vm.entityRequestNew.entityType != null) {
            approveEntityRequest.entityTypeId = vm.entityRequestNew.entityType.id;
          }
          if (vm.entityId != null) {
            approveEntityRequest.id = vm.entityId;
          }

          approveEntityRequest.comments = entityRequestNew.comments;
          approveEntityRequest.flag = entityRequestNew.flag;
          approveEntityRequest.contactEmail = entityRequestNew.contactEmail;
          approveEntityRequest.contactPersonName = entityRequestNew.contactPersonName;
          approveEntityRequest.contactPhoneNumber = entityRequestNew.contactPhoneNumber;

          if (locationValidation && vm.locationIdValidation) {
            if (locationValidation.id) {
              approveEntityRequest.locationMappingId = locationValidation.id;
            } else if (locationValidation.locationMappingId) {
              approveEntityRequest.locationMappingId = locationValidation.locationMappingId;
            }
          } else if (
            entityRequestNew.cityObj.locationMappingId != null &&
            entityRequestNew.cityObj.locationMappingId != undefined
          ) {
            approveEntityRequest.locationMappingId = entityRequestNew.cityObj.locationMappingId;
          } else {
            approveEntityRequest.locationMappingId = vm.entityLocationMappingId;
          }
        }

        // Merged Request
        else {
          approveEntityRequest = angular.copy(vm.entityRequestOld);
          if (vm.isMergeRequest && vm.mergedEntityId != null) {
            approveEntityRequest.mergedEntityId = vm.mergedEntityId;
          }
        }
        if (vm.mergeEntity) {
          approveEntityRequest.isEntityMerge = true;
        } else {
          approveEntityRequest.isEntityMerge = false;
        }

        var data = LibraryRequestService.entityApprove(approveEntityRequest);
        data.then(
          function (response) {
            communicationService.setData("ValidateEntityLocationField", "");
            vm.cancel(requestEntityForm);
            notifications.showSuccess({ message: vm.APP.LIBRARY.ENTITY_REQUEST });
            vm.isEntityApproveDisable = false;
            vm.msgFlag = false;
            vm.mergeEntity = false;
            $state.reload();
          },
          function (reason) {
            if (reason.data.statusCode == 409) {
              vm.isEntityApproveDisable = false;
              vm.msgFlag = true;
              vm.mergeEntity = true;
            } else if (reason.data.statusCode == 400) {
              // if entity approved before location
              vm.isEntityApproveDisable = false;
              vm.msgFlagForLocation = true;
              $timeout(function () {
                vm.msgFlag = false;
                vm.entityPopupId = approveEntityRequest.id;
                vm.popUpId = vm.APP.LIBRARY.ENTITY_REQ;
              }, 5000);
            } else {
              notifications.showError({ message: vm.APP.LIBRARY.ENTITY_REQUEST_PROBLEM });
            }
            return reason;
          }
        );
      } else {
        vm.isEntityApproveDisable = false;
      }
    };
    vm.setFormLocationValues = function () {
      if (vm.locationRequestOld.countryId == null && vm.locationRequestOld.countryTxt != null) {
        vm.formCountryRequest = true;
        vm.locationRequestNew.countryObj.countryId = -1;
        vm.locationRequestNew.provinceObj.provinceId = -1;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.provinceId == null &&
        vm.locationRequestOld.provinceTxt != null &&
        vm.locationRequestOld.provinceTxt != ""
      ) {
        vm.formProvinceRequest = true;
        vm.addNewLocationObject.country = false;
        vm.locationRequestNew.provinceObj.provinceId = -1;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.cityId == null &&
        vm.locationRequestOld.cityTxt != null &&
        vm.locationRequestOld.cityTxt != ""
      ) {
        vm.formCityRequest = true;
        vm.addNewLocationObject.country = false;
        vm.addNewLocationObject.province = false;
        vm.locationRequestNew.cityObj.cityId = -1;
      } else if (
        vm.locationRequestOld.sectorId == null &&
        vm.locationRequestOld.sectorTxt != null &&
        vm.locationRequestOld.sectorTxt != ""
      ) {
        vm.addNewLocationObject.country = false;
        vm.addNewLocationObject.province = false;
        vm.addNewLocationObject.city = false;
        vm.locationRequestNew.sectorObj.sectorId = -1;
      }

      if (
        vm.locationRequestOld.sectorTxt != null &&
        vm.locationRequestOld.sectorTxt != "" &&
        (vm.locationRequestOld.countryId == null ||
          vm.locationRequestOld.provinceId == null ||
          vm.locationRequestOld.cityId == null)
      ) {
        vm.locationRequestNew.sectorObj.sectorId = -1;
      } else if (
        (vm.locationRequestOld.provinceTxt == null || vm.locationRequestOld.provinceTxt == "") &&
        vm.locationRequestOld.provinceId == null
      ) {
        vm.inputIsHideObject.province = true;
        vm.inputIsHideObject.city = true;
        vm.inputIsHideObject.sector = true;
      } else if (
        (vm.locationRequestOld.cityTxt == null || vm.locationRequestOld.cityTxt == "") &&
        vm.locationRequestOld.cityId == null
      ) {
        vm.inputIsHideObject.city = true;
        vm.inputIsHideObject.sector = true;
      } else if (
        (vm.locationRequestOld.sectorTxt == null || vm.locationRequestOld.sectorTxt == "") &&
        vm.locationRequestOld.sectorId == null
      ) {
        vm.inputIsHideObject.sector = true;
      }

      //Set input search object for directive
      if (vm.locationRequestOld.countryTxt != null && vm.locationRequestOld.countryTxt != "") {
        vm.requestLocationInputSearchObject.countrySearchKey = vm.locationRequestOld.countryTxt;
      }
      if (vm.locationRequestOld.provinceTxt != null && vm.locationRequestOld.provinceTxt != "") {
        vm.requestLocationInputSearchObject.provinceSearchKey = vm.locationRequestOld.provinceTxt;
      }
      if (vm.locationRequestOld.cityTxt != null && vm.locationRequestOld.cityTxt != "") {
        vm.requestLocationInputSearchObject.citySearchKey = vm.locationRequestOld.cityTxt;
      }
      if (vm.locationRequestOld.sectorTxt != null && vm.locationRequestOld.sectorTxt != "") {
        vm.requestLocationInputSearchObject.sectorSearchKey = vm.locationRequestOld.sectorTxt;
      }
    };

    vm.requestLocationInputSearchObject = {};
    vm.getRequestProfileById = function (locationId, ev) {
      // To set data in popup
      vm.locationProfile = {};
      vm.requestLocationInputSearchObject = {};
      vm.addNewLocationObject = {};

      vm.requestLocationInputSearchObject.country = "";
      vm.requestLocationInputSearchObject.province = "";
      vm.requestLocationInputSearchObject.city = "";
      vm.requestLocationInputSearchObject.sector = "";

      vm.entityPopupLoader = true;
      vm.isRequestMerged = false;
      vm.inputIsHideObject.country = false;
      vm.inputIsHideObject.province = false;
      vm.inputIsHideObject.city = false;
      vm.inputIsHideObject.sector = false;
      vm.relpaceinputfieldObject.country = false;
      vm.relpaceinputfieldObject.province = false;
      vm.relpaceinputfieldObject.city = false;
      vm.relpaceinputfieldObject.sector = false;
      vm.requestLocationInputSearchObject.isHideCountry = false;
      vm.requestLocationInputSearchObject.isHideProvince = false;
      vm.requestLocationInputSearchObject.isHideCity = false;
      vm.requestLocationInputSearchObject.isHideSector = false;
      vm.formCountryRequest = false;
      vm.formProvinceRequest = false;
      vm.formCityRequest = false;
      vm.addNewLocationObject.country = true;
      vm.addNewLocationObject.province = true;
      vm.addNewLocationObject.city = true;
      vm.addNewLocationObject.sector = true;
      var data = LibraryRequestService.getRequestProfileById(locationId);
      data.then(function (response) {
        vm.locationRequestOld = response;
        vm.locationProfile = response;
        vm.locationRequestNew = angular.copy(vm.locationRequestOld);

        //Set location objects
        vm.locationRequestNew.countryObj = LibraryService.getCountryObj(vm.locationRequestOld);
        vm.locationRequestNew.provinceObj = LibraryService.getProvinceObj(vm.locationRequestOld);
        vm.locationRequestNew.cityObj = LibraryService.getCityObj(vm.locationRequestOld);
        vm.locationRequestNew.sectorObj = LibraryService.getSectorObj(vm.locationRequestOld);

        //Set LocationType
        if (vm.locationRequestOld.requestTypeName != null) {
          vm.locationTypeName = vm.locationRequestOld.requestTypeName;
        } else if (vm.locationRequestOld.requestTypeName == null) {
          vm.locationTypeName = vm.APP.LIBRARY.FORM_LOCATION_REQUEST;
        }

        //Get merged location obj
        if (vm.locationRequestNew.isMerged) {
          vm.isRequestMerged = true;
          var mergedData = LibraryRequestService.getRequestProfileById(vm.locationRequestNew.mergedLocationMappingId);
          mergedData.then(function (response) {
            vm.locationRequestNew = response;
          });
        }
        //For Showing new request in md-select drop down
        else {
          if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.COUNTRY) {
            vm.inputIsHideObject.province = true;
            vm.inputIsHideObject.city = true;
            vm.inputIsHideObject.sector = true;
            vm.locationRequestNew.countryObj.countryId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE) {
            vm.inputIsHideObject.city = true;
            vm.inputIsHideObject.sector = true;
            vm.addNewLocationObject.country = false;
            vm.locationRequestNew.provinceObj.provinceId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY) {
            vm.inputIsHideObject.sector = true;
            vm.addNewLocationObject.country = false;
            vm.addNewLocationObject.province = false;
            vm.locationRequestNew.cityObj.cityId = -1;
          } else if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR) {
            vm.addNewLocationObject.country = false;
            vm.addNewLocationObject.province = false;
            vm.addNewLocationObject.city = false;
            vm.locationRequestNew.sectorObj.sectorId = -1;
          }

          //Set the form request values
          if (vm.locationTypeName == vm.APP.LIBRARY.FORM_LOCATION_REQUEST) {
            vm.setFormLocationValues();
          }
        }
        vm.getRequestPopupId(ev, vm.APP.LIBRARY.LOCATION_REQUEST);
        vm.entityPopupLoader = false;
      });
    };
    //To open location popup in investigation listing page
    vm.getLocationPopup = function (ev, entityRequestNew, requestEntityForm) {
      vm.getRequestProfileById(entityRequestNew.locationMappingId, ev);
    };

    //Show the validation message for form location request
    vm.validation = function () {
      if (vm.approveObj.countryTxt == null || vm.approveObj.countryTxt == "" || vm.approveObj.countryTxt == undefined) {
        vm.requestLocationInputSearchObject.countrySearchKey = "";
        vm.validationFlag = true;
      }
      if (
        vm.approveObj.provinceTxt == null ||
        vm.approveObj.provinceTxt == "" ||
        vm.approveObj.provinceTxt == undefined
      ) {
        vm.requestLocationInputSearchObject.provinceSearchKey = "";
        vm.validationFlag = true;
      }
      if (vm.approveObj.cityTxt == null || vm.approveObj.cityTxt == "" || vm.approveObj.cityTxt == undefined) {
        vm.requestLocationInputSearchObject.citySearchKey = "";
        vm.validationFlag = true;
      }
      if (vm.locationRequestOld.sectorTxt != null && vm.locationRequestOld.sectorTxt != "") {
        if (vm.approveObj.sectorTxt == null || vm.approveObj.sectorTxt == "" || vm.approveObj.sectorTxt == undefined) {
          vm.validationFlag = true;
          vm.sectorValidationFlag = true; //Show sector validation message
        }
      }
      $timeout(function () {
        vm.validationFlag = false;
        vm.sectorValidationFlag = false;
      }, 5000);
    };

    //To check the location object while approve location popup
    vm.approveAlterFormLocationRequest = function (locationRequestNew, inputSearchObject, ev) {
      vm.approveObj = {};
      vm.sectorValidationFlag = false;
      vm.validationFlag = false;
      vm.isLocationApproveDisable = true;
      if (locationRequestNew) {
        vm.approveObj.id = locationRequestNew.id;

        if (
          (vm.locationRequestOld.countryId == null || inputSearchObject.countryAltered) &&
          inputSearchObject.countrySearchKey != null &&
          inputSearchObject.countrySearchKey != ""
        ) {
          vm.approveObj.countryTxt = inputSearchObject.countrySearchKey;
        } else if (
          locationRequestNew.countryObj != undefined &&
          locationRequestNew.countryObj.countryId != -1 &&
          locationRequestNew.countryObj.countryId != null
        ) {
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
        }

        if (
          (vm.locationRequestOld.provinceId == null || inputSearchObject.provinceAltered) &&
          vm.locationRequestOld.provinceTxt != null &&
          vm.locationRequestOld.provinceTxt != "" &&
          inputSearchObject.provinceSearchKey != null &&
          inputSearchObject.provinceSearchKey != ""
        ) {
          vm.approveObj.provinceTxt = inputSearchObject.provinceSearchKey;
        } else if (
          vm.locationRequestOld.provinceTxt != null &&
          vm.locationRequestOld.provinceTxt != "" &&
          locationRequestNew.provinceObj != undefined
        ) {
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
        }

        if (
          (vm.locationRequestOld.cityId == null || inputSearchObject.cityAltered) &&
          vm.locationRequestOld.cityTxt != null &&
          vm.locationRequestOld.cityTxt != "" &&
          inputSearchObject.citySearchKey != null &&
          inputSearchObject.citySearchKey != ""
        ) {
          vm.approveObj.cityTxt = inputSearchObject.citySearchKey;
        } else if (
          vm.locationRequestOld.cityTxt != null &&
          vm.locationRequestOld.cityTxt != "" &&
          locationRequestNew.cityObj != undefined
        ) {
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
        }

        if (
          locationRequestNew.cityObj != undefined &&
          locationRequestNew.cityObj.cityId != -1 &&
          locationRequestNew.cityObj.cityId != null
        ) {
          //Set merged Location mapping Id Value for City
          if (
            (vm.formCountryRequest || vm.formProvinceRequest || vm.formCityRequest) &&
            locationRequestNew.sectorObj.sectorId != -1 &&
            angular.isUndefinedOrNull(locationRequestNew.sectorObj.sectorId)
          ) {
            vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.cityObj.locationMappingId;
          }
        }

        if (
          vm.locationRequestOld.sectorTxt != null &&
          vm.locationRequestOld.sectorTxt != "" &&
          locationRequestNew.sectorObj != undefined &&
          locationRequestNew.sectorObj.sectorId != -1 &&
          locationRequestNew.sectorObj.sectorId != null
        ) {
          vm.approveObj.sectorId = locationRequestNew.sectorObj.sectorId;
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.sectorObj.locationMappingId; //Set merged Location mapping Id Value for City
        } else if (
          (vm.locationRequestOld.sectorTxt != null && locationRequestNew.sectorObj.sectorId == -1) ||
          locationRequestNew.sectorObj.sectorId == undefined
        ) {
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          if (
            inputSearchObject.sectorSearchKey != null &&
            (inputSearchObject.sectorSearchKey != "" || inputSearchObject.sectorAltered)
          ) {
            vm.approveObj.sectorTxt = inputSearchObject.sectorSearchKey;
          }
        }
        //Check the location object for validation
        vm.validation();
        if (vm.mergeFlag) {
          vm.approveObj.isMerge = true;
        } else {
          vm.approveObj.isMerge = false;
        }
        if (!vm.validationFlag) {
          var data = LibraryRequestService.saveApprovedRequestLists(vm.approveObj);
          data.then(
            function (response) {
              vm.msgFlag = false;
              vm.mergeFlag = false;
              $mdDialog.hide();
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_REQUEST });
              vm.isLocationApproveDisable = false;
              if (vm.entityPopupId && vm.popUpId == vm.APP.LIBRARY.ENTITY_REQ) {
                $timeout(function () {
                  vm.msgFlagForLocation = false;
                  vm.popUpId = "";
                  $rootScope.getEntityProfileById(vm.entityPopupId, false, ev);
                }, 1000);
              }
            },
            function (reason) {
              if (reason.data.statusCode == 409) {
                vm.msgFlag = true;
                vm.mergeFlag = true;
              } else {
                notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_PROBLEM });
              }
              vm.isLocationApproveDisable = false;
              return reason;
            }
          );
        } else {
          vm.isLocationApproveDisable = false;
        }
      } else {
        vm.isLocationApproveDisable = false;
      }
    };
    //To open entity or location popup
    vm.showRequestDialog = function ($event, id) {
      //vm.request = request;
      vm.isDisable = false;
      vm.isHide = true;
      vm.isNewEntityName = true;
      $mdDialog.show({
        targetEvent: $event,
        contentElement: "#" + id,
        parent: angular.element(document.body)
      });
    };

    vm.approvAlterLocationRequest = function (locationRequestNew, requestLocationForm, ev) {
      vm.approveObj = {};
      vm.approveObj.countryObj = {};
      vm.approveObj.provinceObj = {};
      vm.approveObj.cityObj = {};
      vm.approveObj.sectorObj = {};
      vm.sectorValidationFlag = false;
      vm.isLocationApproveDisable = true;
      if (locationRequestNew && requestLocationForm.$valid) {
        vm.approveObj.id = vm.locationRequestNew.id;
        vm.approveObj.requestTypeId = locationRequestNew.requestTypeId;
        vm.approveObj.requestTypeName = locationRequestNew.requestTypeName;
        if (vm.locationRequestNew.requestTypeName == vm.APP.LIBRARY.COUNTRY) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          if (locationRequestNew.countryObj && locationRequestNew.countryObj.locationMappingId != null) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.countryObj.locationMappingId;
          } else if (locationRequestNew.countryObj.countryId == -1) {
            vm.approveObj.countryId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.PROVINCE) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          if (
            locationRequestNew.provinceObj &&
            locationRequestNew.countryObj &&
            locationRequestNew.provinceObj.locationMappingId != null
          ) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.provinceObj.locationMappingId;
          } else if (vm.locationRequestNew.provinceObj.provinceId == -1) {
            vm.approveObj.provinceId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.CITY) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          if (
            locationRequestNew.countryObj &&
            locationRequestNew.cityObj.locationMappingId != null &&
            locationRequestNew.cityObj
          ) {
            vm.approveObj.mergedLocationMappingId = vm.locationRequestNew.cityObj.locationMappingId;
          } else if (locationRequestNew.cityObj.cityId == -1) {
            vm.approveObj.cityId = null;
          }
        }
        if (locationRequestNew.requestTypeName == vm.APP.LIBRARY.SECTOR) {
          vm.approveObj.countryId = locationRequestNew.countryObj.countryId;
          vm.approveObj.countryTxt = locationRequestNew.countryObj.countryTxt;
          vm.approveObj.provinceId = locationRequestNew.provinceObj.provinceId;
          vm.approveObj.provinceTxt = locationRequestNew.provinceObj.provinceTxt;
          vm.approveObj.cityId = locationRequestNew.cityObj.cityId;
          vm.approveObj.cityTxt = locationRequestNew.cityObj.cityTxt;
          vm.approveObj.sectorId = locationRequestNew.sectorObj.sectorId;
          vm.approveObj.sectorTxt = locationRequestNew.sectorObj.sectorTxt;
          if (
            locationRequestNew.countryObj &&
            locationRequestNew.sectorObj.locationMappingId != null &&
            locationRequestNew.cityObj &&
            locationRequestNew.sectorObj
          ) {
            vm.approveObj.mergedLocationMappingId = locationRequestNew.sectorObj.locationMappingId;
          } else if (locationRequestNew.sectorObj.sectorId == -1) {
            vm.approveObj.sectorId = null;
          }
          if (vm.approveObj.sectorTxt == null || vm.approveObj.sectorTxt == "") {
            vm.sectorValidationFlag = true; //Show sector validation message
            $timeout(function () {
              vm.sectorValidationFlag = false;
            }, 5000);
          }
        }

        if (vm.mergeFlag) {
          vm.approveObj.isMerge = true;
        } else {
          vm.approveObj.isMerge = false;
        }
        if (!vm.sectorValidationFlag) {
          var data = LibraryRequestService.saveApprovedRequestLists(vm.approveObj);
          data.then(
            function (response) {
              vm.msgFlag = false;
              vm.mergeFlag = false;
              $mdDialog.hide();
              notifications.showSuccess({ message: vm.APP.LIBRARY.LOCATION_REQUEST });
              vm.isLocationApproveDisable = false;
              if (vm.entityPopupId && vm.popUpId == vm.APP.LIBRARY.ENTITY_REQ) {
                $timeout(function () {
                  vm.msgFlagForLocation = false;
                  vm.popUpId = "";
                  $rootScope.getEntityProfileById(vm.entityPopupId, false, ev);
                }, 1000);
              }
            },
            function (reason) {
              if (reason.data.statusCode == 409) {
                vm.msgFlag = true;
                vm.mergeFlag = true;
              } else {
                notifications.showError({ message: vm.APP.LIBRARY.LOCATION_REQUEST_PROBLEM });
              }
              vm.isLocationApproveDisable = false;
              return reason;
            }
          );
        }
      } else {
        vm.isLocationApproveDisable = false;
      }
    };

    vm.validateLocationIds = function (locationValidation) {
      if (
        locationValidation &&
        locationValidation.countryId &&
        locationValidation.provinceId &&
        locationValidation.cityId
      ) {
        vm.locationIdValidation = true;
      }
    };

    // Added the show for DNA new filters
    vm.dnaFiltersShow = function (show) {
      if (show == "dna") {
        if ($scope.dnaIcon) {
          $scope.dnaIcon = false;
        } else {
          $scope.dnaIcon = true;
        }
      } else if (show) {
        $scope.dnaIcon = false;
      } else {
        $scope.dnaIcon = true;
      }
    };
  }
})();
